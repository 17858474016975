<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addDocumentClause') : $t('message.updateDocument') + ": " + documentTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="clauseForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.title') }}</v-flex>
                        <v-flex xs6 lg9 md7 py-1>
                            <v-lazy>
                                <v-textarea
                                        :value="SdaDocument__title"
                                        :rules="[...validationRules.required, ...validationRules.paragraph]"
                                        auto-grow
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        rows="1"
                                        solo
                                        @change="SdaDocument__title = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs6 lg9 md7 py-1>
                            <v-select
                                    :items="docTypes"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="SdaDocument__sdadoctype_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.template') }}</v-flex>
                        <v-flex xs6 lg9 md7 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    autocomplete="off"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    readonly
                                    solo
                                    v-model="SdaDocument__template"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addDocument()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveDocument()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AddShippingInstructionClause",
    props: ['documentId','documentTitle','dialog','updateMode','dialog-closed','update-done','create-done','pageKey'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            docTypes: [
                {
                    text: this.$t('message.standardDocuments'),
                    value: 1
                },
                {
                    text: this.$t('message.countryCustomerSpecificDocuments'),
                    value: 2
                },
                {
                    text: this.$t('message.otherRequests'),
                    value: 3
                },
                {
                    text: this.$t('message.presentationDocuments'),
                    value: 4
                }
            ],
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            // rules: {
            //     title: v => !!v || this.$t('message.required'),
            //     docType: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('sdadocument', {
            SdaDocument__id: 'SdaDocument.id',
            SdaDocument__title: 'SdaDocument.title',
            SdaDocument__sdadoctype_id: 'SdaDocument.sdadoctype_id',
            SdaDocument__template: 'SdaDocument.template'
        }, 'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('sdadocument', {
            createSdaDocument: 'createSdaDocument',
            getSdaDocumentToUpdateById: 'getSdaDocumentToUpdateById',
            resetCurrentState: 'resetCurrentState',
            resetSdaDocumentToUpdate: 'resetSdaDocumentToUpdate',
            updateSdaDocument: 'updateSdaDocument'
        }),
        addDocument() {
            if (this.$refs.clauseForm.validate()) {
                this.loading.add = true
                this.createSdaDocument()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.documentClauseAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.documentClauseNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.documentClauseNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentClauseNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveDocument() {
            if (this.$refs.clauseForm.validate()) {
                this.loading.save = true
                this.updateSdaDocument()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.documentClauseUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.documentClauseNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.documentClauseNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentClauseNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getSdaDocumentToUpdateById(this.documentId)
                        .then( () => {
                            this.loading.get = false
                        })
                }
            } else {
                if(this.updateMode == true) {
                    this.resetSdaDocumentToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        // this.resetAllParentSpecies()
        // if(this.allParentSpecies.length == 0) this.getAllParentSpecies()
        // if(this.allProductGroups.length == 0) this.getAllProductGroups()
    }
}
</script>

<style scoped>

</style>